import { cloneElement, FC } from 'react';
import { useField } from 'formik';
import TextUnderInput from './TextUnderInput';
import { FormInputContainer } from '../FormInputs.style';
import { FormInputProps } from '../FormInputs.types';
import InputLabelWithInfo from '../../InputLabelWithInfo/InputLabelWithInfo';
import ReadMore from '../../../ReadMore/ReadMore';
import * as S from './FormInput.style';

const FormInputGeneric: FC<FormInputProps> = ({
  testId,
  fieldName,
  textUnderInput,
  id,
  children,
  useCustomOnChange = false,
  readMoreLink = '',
  valueOverride,
  ...rest
}) => {
  const { hoverableInfoIconProps, label, className, ...inputProps } = rest;
  const [{ onChange, ...field }, meta] = useField(fieldName);

  return (
    <FormInputContainer {...{ className }}>
      {label && (
        <S.FlexContainer>
          <InputLabelWithInfo
            hoverableInfoIconProps={hoverableInfoIconProps}
            label={label}
            testId={testId}
            htmlFor={id}
          />
          {readMoreLink && (
            <ReadMore
              readMoreLink={readMoreLink}
              dataTestId={`link--read-more-${testId}`}
              withText={false}
            />
          )}
        </S.FlexContainer>
      )}
      {cloneElement(children, {
        id: id || fieldName,
        name: fieldName,
        error: meta.error,
        inputTestId: testId,
        ...field,
        ...(valueOverride && { value: valueOverride }),
        ...inputProps,
        ...(!useCustomOnChange && { onChange })
      })}
      {textUnderInput && <TextUnderInput textUnderInput={textUnderInput} />}
    </FormInputContainer>
  );
};

export default FormInputGeneric;
