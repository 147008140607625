import { FC, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';

import { FormInput } from '../../../../../../components';
import CountrySelectInput from '../../../../../../components/Shared/InputFields/CountrySelectInput';
import StateSelectInput from '../../../../../../components/Shared/InputFields/StateSelectInput';
import { paymentInfosQueries } from '../../../../../../queryClient/paymentInfos/paymentInfos.queries';
import * as S from './SignUpForm.style';
import { SignUpFormValues } from './SignUpForm.types';
import { SubmitButton } from './SignUpForm.style';

const SignUpForm: FC = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.signUpScreen;
  const { data: countriesData } = paymentInfosQueries.useCountries();
  const [states, setStates] = useState<State[]>([]);
  const {
    values,
    setValues,
    errors,
    handleChange,
    isValid,
    isSubmitting,
    submitForm,
    dirty,
    touched
  } = useFormikContext<SignUpFormValues>();

  const handleSubmit = () => submitForm();

  const getCurrentCountryStates = (countryCode: string) =>
    countriesData.states.filter((state) => state.ccode === countryCode);

  const onCountrySelect = (selectedCountry: Country) => {
    const currentCountryStates = getCurrentCountryStates(selectedCountry.code);
    setStates(currentCountryStates);

    setValues(
      {
        ...values,
        state: '',
        state_code: '',
        country: selectedCountry.code,
        country_code: selectedCountry.name
      },
      true
    );
  };

  const onStateSelect = (state: State) => {
    setValues(
      {
        ...values,
        state: state.code,
        state_code: state.name
      },
      true
    );
  };

  const getSelectedState = () => {
    const currentFieldSelectedState = values?.state as string;

    return states.find(
      (state) => state?.code?.toLocaleLowerCase() === currentFieldSelectedState.toLocaleLowerCase()
    );
  };

  const getSelectedCountryCode = () => values.country;

  return (
    <S.FullHeightForm noValidate>
      <S.Container>
        <FormInput
          type="email"
          autoComplete="new-email"
          testId="email-address"
          label={t(keyPrefix.email)}
          fieldName="email"
          width="40rem"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange('email')}
        />

        <S.InlineInputsWrapper>
          <FormInput
            testId="first-name"
            autoComplete="new-firstName"
            label={t(keyPrefix.firstName)}
            fieldName="first_name"
            width="100%"
            value={values.first_name}
            error={touched.first_name && errors.first_name}
            onChange={handleChange('first_name')}
          />
          <FormInput
            testId="last-name"
            autoComplete="new-lastName"
            label={t(keyPrefix.lastName)}
            fieldName="last_name"
            width="100%"
            value={values.last_name}
            error={touched.last_name && errors.last_name}
            onChange={handleChange('last_name')}
          />
        </S.InlineInputsWrapper>

        <CountrySelectInput
          width="100%"
          countries={countriesData.countries}
          label={t(keyPrefix.country)}
          onCountrySelect={onCountrySelect}
          selectedCountryCode={getSelectedCountryCode()}
        />

        {!!states.length && (
          <StateSelectInput
            label={t(keyPrefix.state)}
            width="100%"
            states={states}
            onStateSelect={onStateSelect}
            selectedState={getSelectedState()}
          />
        )}

        <FormInput
          testId="password"
          autoComplete="new-password"
          type="password"
          label={t(keyPrefix.password)}
          fieldName="password"
          width="40rem"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange('password')}
        />
        <FormInput
          testId="repeated-password"
          autoComplete="new-confirmPassword"
          type="password"
          label={t(keyPrefix.confirmPassword)}
          fieldName="confirm_password"
          width="40rem"
          value={values.confirm_password}
          error={touched.confirm_password && errors.confirm_password}
          onChange={handleChange('confirm_password')}
        />

        <label data-testid="label--i-agree" htmlFor="agreement">
          <Field
            data-testid="checkbox--i-agree"
            name="agreement"
            type="checkbox"
            error={errors.agreement}
          />
          <S.InlineTerms>
            {t(keyPrefix.agreement)}
            <a
              className="inline-anchor"
              href="https://redis.com/cloud-tos"
              target="_blank"
              rel="noreferrer"
            >
              {t(keyPrefix.termsOfUse)}
            </a>
            {t(keyPrefix.and)}
            <a
              className="inline-anchor"
              href="https://redis.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              {t(keyPrefix.privacyPolicy)}
            </a>
          </S.InlineTerms>
        </label>

        <S.SubmitButton disabled={!dirty || !isValid || isSubmitting} onClick={handleSubmit}>
          {t(keyPrefix.submit)}
        </S.SubmitButton>
      </S.Container>
    </S.FullHeightForm>
  );
};

export default SignUpForm;
