import styled, { css } from 'styled-components/macro';
import ReadMore from '../../../ReadMore/ReadMore';

export const Container = styled.div`
  display: flex;

  //& > *:nth-child(2) {
  //  line-height: 0;
  //}
`;

export const InputLabel = styled.label`
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const ReadMoreLink = styled(ReadMore)<{ withMargin?: boolean }>`
  margin-left: ${({ withMargin }) => (withMargin ? 'auto' : undefined)};
`;

export const Optional = styled.span`
  font-size: 1.2rem;
  color: #c1cbd9;
  margin-left: 0.5rem;
`;
