import { FormField, Input, PasswordInput, RestInputProps } from '@redislabsdev/redis-ui-components';
import { FormInputProps } from './FormInput.types';
import { useFormikField } from '../../../../hooks/useFormikField';
import * as S from './FormInput.style';
import LabelWithLink from '../LabelWithLink/LabelWithLink';

const FormInput = ({
  className,
  testId,
  fieldName,
  width,
  type,

  textUnderInput,
  id,

  label,
  optional,
  required,
  infoIconProps,
  readMore,
  ...inputProps
}: FormInputProps & RestInputProps) => {
  const [field, meta] = useFormikField(fieldName);
  const inputTestId = inputProps.readOnly ? `readonlyvalue--${testId}` : `textinput--${testId}`;

  return (
    <S.FormFieldCompose className={className} data-testid={`form-container--${testId}`}>
      <LabelWithLink
        label={label}
        optional={optional}
        required={required}
        testId={testId}
        readMore={readMore}
        infoIconProps={infoIconProps}
      />
      {type === 'password' ? (
        <PasswordInput
          id={id || testId}
          error={meta.touched ? meta.error : undefined}
          autoComplete="off"
          data-testid={inputTestId}
          data-lpignore="true"
          {...field}
          value={field.value || ''}
          style={{ width }}
          {...inputProps}
        />
      ) : (
        <Input
          id={id || testId}
          error={meta.touched ? meta.error : undefined}
          autoComplete="off"
          data-testid={inputTestId}
          data-lpignore="true"
          {...field}
          value={field.value || ''}
          style={{ width }}
          type={type}
          {...inputProps}
        />
      )}
      <FormField.AdditionalText>{textUnderInput}</FormField.AdditionalText>
    </S.FormFieldCompose>
  );
};

export default FormInput;
