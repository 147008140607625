import { Label } from '@redislabsdev/redis-ui-components';
import { LabelWithLinkProps } from './LabelWithLink.types';
import * as S from './LabelWithLink.style';

const LabelWithLink = ({ testId, readMore, className, ...restProps }: LabelWithLinkProps) => {
  return (
    <S.Container className={className}>
      <Label data-testid={`readonlylabel--${testId}`} {...restProps} />
      {readMore?.link && (
        <S.ReadMoreLink
          readMoreLink={readMore.link}
          size="L"
          withText={!!readMore.text}
          dataTestId={readMore?.testId}
        >
          {readMore.text}
        </S.ReadMoreLink>
      )}
    </S.Container>
  );
};

export default LabelWithLink;
