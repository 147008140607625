import * as S from './FormInput.style';

interface IProps {
  textUnderInput: string;
}

const getTexts = (textUnderInput: string) => {
  const [regularText, boldText] = textUnderInput.split(':', 2);

  return [`${regularText}: `, boldText];
};

const TextUnderInput = ({ textUnderInput }: IProps) => {
  const [regularText, boldText] = getTexts(textUnderInput);

  return (
    <S.UnderInputText>
      {regularText}
      {boldText && <S.BoldText>{boldText}</S.BoldText>}
    </S.UnderInputText>
  );
};

export default TextUnderInput;
